/* eslint-disable react/no-array-index-key */
import React from "react"
import BackgroundImage from "../components/background-image"
import Icon from "../../icons"
import { SectionType } from "../../../types"
import { createMarkup } from "../../../../utils/markup-creator"

type SectionProps = {
  section: SectionType
  index: any
  [key: string]: any
}

const TestimonialsSection = ({ section, index, ...props }: SectionProps) => {
  
  return (
    <div className={`relative ${section.advanced?.class || ``}`}>
      <BackgroundImage
        url={section.advanced?.backgroundImageLg}
        mobileUrl={section.advanced?.backgroundImageSm}
        height={section.advanced?.backgroundHeight}
        backgroundPosition={section.advanced?.backgroundPosition}
        backgroundColor={section.advanced?.backgroundColor}
        backgroundFit={section.advanced?.backgroundFit}
      />
      <div className="max-w-screen-lg mx-auto px-4 sm:px-6 lg:px-0">
        {section.blurb && <div dangerouslySetInnerHTML={createMarkup(section.blurb)} />}
        <div className="mt-10">
          <dl className="space-y-5 md:space-y-0 md:grid md:grid-cols-3 md:gap-x-8 md:gap-y-8">
            {section.testimonials.map((testimonial, testimonialIndex) => (
              <div className="p-8 bg-background border-solid border border-primary-200 rounded-lg shadow-xl" key={`testimonial-${testimonialIndex}`}>
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <span className="sr-only">{testimonial.name}</span>
                    { testimonial.image?.includes('images/') && <img className="h-12 w-12 rounded-full" src={testimonial.image} alt="" /> }
                    { !testimonial.image?.includes('images/') && <Icon icon={testimonial.image} className="h-12 w-12 rounded-full" /> }
                  </div>
                  <div className="ml-3">
                    <span className="text-lg font-medium">{testimonial.name}</span>
                    <div className="flex space-x-1 text-sm">{testimonial.location}</div>
                  </div>
                </div>

                <div className="block mt-4">
                  <p className="mt-3 text-base">{testimonial.testimonial}</p>
                </div>
              </div>
            ))}
          </dl>
        </div>
        {section.blurb2 && <div dangerouslySetInnerHTML={createMarkup(section.blurb2)} />}
      </div>
    </div>
  )
}

export default TestimonialsSection
